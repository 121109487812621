import lodashSet from 'lodash/set';
import LayoutActions from '../actions/LayoutActions';

const initialState = JSON.parse('{}');

const LayoutReducer = (state = initialState, action = {}) => {
    let changes = {};
    switch (action.type) {
        case LayoutActions.LAYOUT_FETCH_COMPLETED:
            changes = {
                ...action.payload,
                error: null,
                layoutReset: false,
                isFetching: false,
            };
            break;

        case LayoutActions.LAYOUT_FETCH_ERROR: {
            const { pageId, error } = action.payload;
            if (!pageId) {
                changes = {
                    error,
                    isFetching: false,
                };
            }
            else {
                const oldLayoutData = state[pageId] || {};

                changes = {
                    ...state,
                    [pageId]: {
                        ...oldLayoutData,
                        error,
                        isSSRData: false,
                    },
                    isFetching: false,
                };
            }
        }
            break;

        case LayoutActions.PURGE_LAYOUT_DATA:
            return { layoutReset: true }; // / clear all data

        case LayoutActions.PACKAGE_RESET: {
            const newState = { ...state };
            const { pageId } = action;

            delete newState[pageId];
            return newState;
        }

        case LayoutActions.IS_SSR_DATA_RESET: {
            const newState = { ...state };
            Object.keys(newState).forEach((key) => {
                lodashSet(newState[key], 'isSSRData', false);
            });
            return newState;
        }

        case LayoutActions.PURGE_STORE_LAYOUT_DATA:
            return {};// clear all data AND STORE

        case LayoutActions.RESTORE_LAYOUT_DATA:
            return { ...action.payload };

        case LayoutActions.SET_FOCUSED_TILE_ID: {
            const newState = { ...state };
            const { pageId = '', focusedTileId = {} } = action;
            if (pageId && newState[pageId]) {
                newState[pageId].focusedTileId = focusedTileId;
            }
            return newState;
        }
        case LayoutActions.RESET_FOCUSED_TILE_ID: {
            const newState = { ...state };
            const { pageId = '' } = action;
            if (pageId && newState[pageId]) {
                newState[pageId].focusedTileId = '';
            }
            return newState;
        }
        case LayoutActions.SET_LAST_FOCUS_DETAILS: {
            const newState = { ...state };
            const { pageId = '', lastFocusDetails = {} } = action;
            if (pageId && newState[pageId]) {
                newState[pageId].lastFocusDetails = lastFocusDetails;
            }
            return newState;
        }
        case LayoutActions.PURGE_LAYOUT_EXCLUDING: {
            const { retainPageId = '' } = state;
            const { pageId = retainPageId } = action;
            const newState = {
                [pageId]: state[pageId],
                retainPageId: pageId,
            };
            return newState;
        }
        case LayoutActions.IS_FETCHING_LAYOUT: {
            const newState = {
                ...state,
                isFetching: action.isFetching,
            };
            return newState;
        }
        case LayoutActions.SET_LAYOUT_DIMENSIONS_MAP: {
            const {
                pageId = '',
                railPosition = 0,
                dimensions = {},
            } = action.payload || {};
            const newState = { ...state };
            if (pageId && newState[pageId] && dimensions) {
                const existingDimesions = (newState[pageId]?.railTileDimensionsMap && newState[pageId]?.railTileDimensionsMap[railPosition]) || {};
                if (newState[pageId].railTileDimensionsMap) {
                    newState[pageId].railTileDimensionsMap[railPosition] = {
                        ...existingDimesions,
                        ...dimensions,
                        railPosition,
                    };
                }
            }
            return newState;
        }
        case LayoutActions.LAYOUT_RAIL_SCROLL_TOP_UPDATE:
            const { railPosition, pageId } = action.payload || {};
            const newState = { ...state };
            newState[pageId].layoutRailScrollTopUpdate = {
                railPosition,
                pageId,
            };
            return newState;
        case LayoutActions.RESET_LAYOUT_DIMENSIONS_MAP: {
            const {
                pageId = '',
                railPosition = 0,
            } = action.payload || {};
            const newState = { ...state };
            if (pageId && newState[pageId]) {
                newState[pageId].railTileDimensionsMap[railPosition] = null;
            }
            return newState;
        }
        case LayoutActions.SET_X_FULL_BANNER_HEIGHT:
            changes = {
                ...action.payload,
            };
            break;
        case LayoutActions.SEARCH_LAYOUT_UPDATE: {
            const {
                isSearchLayout, isEmptyRail, layoutApiError, isFetchedData,
            } = action?.payload || {};
            changes = {
                searchLayoutMeta: {
                    isSearchLayout,
                    isEmptyRail,
                    layoutApiError,
                    isFetchedData,
                },
            };
            break;
        }
        case LayoutActions.SEARCH_LAYOUT_RESET: {
            const { isSearchLayout } = action?.payload || {};
            changes = {
                searchLayoutMeta: {
                    isSearchLayout,
                    isEmptyRail: true,
                    layoutApiError: false,
                    isFetchedData: false,
                },
            };
            break;
        }
        case LayoutActions.RAIL_SCROLL_TOP_ADDITION: {
            const newState = { ...state };
            const { pageId = '', railScrollAddition = 0 } = action?.payload || {};
            if (pageId && newState[pageId]) {
                newState[pageId].railScrollAddition = (newState[pageId]?.railScrollAddition || 0) + railScrollAddition;
            }
            return newState;
        }
        case LayoutActions.REFRESH_DIMENSION_MAP: {
            const newState = { ...state };
            newState.shouldRefreshDimensionMap = action.shouldRefreshDimensionMap;
            return newState;
        }
        default:
            return state;
    }

    return {
        ...state,
        ...changes,
    };
};

export default LayoutReducer;
