const LayoutActions = {
    LAYOUT_FETCH: 'LAYOUT_FETCH',
    LAYOUT_PAGINATED_FETCH: 'LAYOUT_PAGINATED_FETCH',
    ALL_LAYOUT_FETCH: 'ALL_LAYOUT_FETCH',
    LAYOUT_FETCH_ERROR: 'LAYOUT_FETCH_ERROR',
    LAYOUT_FETCH_COMPLETED: 'LAYOUT_FETCH_COMPLETED',
    LAYOUT_RESET: 'LAYOUT_RESET',
    IS_SSR_DATA_RESET: 'IS_SSR_DATA_RESET',
    PURGE_LAYOUT_DATA: 'PURGE_LAYOUT_DATA',
    SUBSCRIPTION_LAYOUT_PAGINATED_FETCH: 'SUBSCRIPTION_LAYOUT_PAGINATED_FETCH',
    PACKAGE_RESET: 'PACKAGE_RESET',
    PURGE_STORE_LAYOUT_DATA: 'PURGE_STORE_LAYOUT_DATA',
    RESTORE_LAYOUT_DATA: 'RESTORE_LAYOUT_DATA',
    SET_FOCUSED_TILE_ID: 'SET_FOCUSED_TILE_ID',
    RESET_FOCUSED_TILE_ID: 'RESET_FOCUSED_TILE_ID',
    PURGE_LAYOUT_EXCLUDING: 'PURGE_LAYOUT_EXCLUDING',
    SET_LAST_FOCUS_DETAILS: 'SET_LAST_FOCUS_DETAILS',
    IS_FETCHING_LAYOUT: 'IS_FETCHING_LAYOUT',
    SET_LAYOUT_DIMENSIONS_MAP: 'SET_LAYOUT_DIMENSIONS_MAP',
    RESET_LAYOUT_DIMENSIONS_MAP: 'RESET_LAYOUT_DIMENSIONS_MAP',
    SEARCH_LAYOUT_UPDATE: 'SEARCH_LAYOUT_UPDATE',
    SEARCH_LAYOUT_RESET: 'SEARCH_LAYOUT_RESET',
    SET_X_FULL_BANNER_HEIGHT: 'SET_X_FULL_BANNER_HEIGHT',
    LAYOUT_RAIL_SCROLL_TOP_UPDATE: 'LAYOUT_RAIL_SCROLL_TOP_UPDATE',
    UPDATE_USER_CONFIG_PURGE_LAYOUT: 'UPDATE_USER_CONFIG_PURGE_LAYOUT',
    RAIL_SCROLL_TOP_ADDITION: 'RAIL_SCROLL_TOP_ADDITION',
    REFRESH_DIMENSION_MAP: 'REFRESH_DIMENSION_MAP',
};

export default LayoutActions;

export const layoutFetchAction = payload => ({
    type: LayoutActions.LAYOUT_FETCH,
    ...payload,
});

export const paginatedLayoutFetchAction = payload => ({
    type: LayoutActions.LAYOUT_PAGINATED_FETCH,
    ...payload,
});

export const paginatedSubscriptionLayoutFetchAction = payload => ({
    type: LayoutActions.SUBSCRIPTION_LAYOUT_PAGINATED_FETCH,
    ...payload,
});

export const allLayoutFetchAction = payload => ({
    type: LayoutActions.ALL_LAYOUT_FETCH,
    ...payload,
});

export const layoutFetchActionSuccess = payload => ({
    type: LayoutActions.LAYOUT_FETCH_COMPLETED,
    ...payload,
});

export const layoutFetchActionError = payload => ({
    type: LayoutActions.LAYOUT_FETCH_ERROR,
    ...payload,
});

export const layoutReset = () => ({
    type: LayoutActions.LAYOUT_RESET,
});

export const packageReset = payload => ({
    type: LayoutActions.PACKAGE_RESET,
    ...payload,
});

export const isSSRDataReset = () => ({
    type: LayoutActions.IS_SSR_DATA_RESET,
});

export const purgeLayoutData = () => ({
    type: LayoutActions.PURGE_LAYOUT_DATA,
});

export const purgeStoreLayoutData = () => ({
    type: LayoutActions.PURGE_STORE_LAYOUT_DATA,
});

export const restoreLayoutData = payload => ({
    type: LayoutActions.RESTORE_LAYOUT_DATA,
    ...payload,
});

export const setFocusedTileId = payload => ({
    type: LayoutActions.SET_FOCUSED_TILE_ID,
    ...payload,
});

export const resetFocusedTileId = payload => ({
    type: LayoutActions.RESET_FOCUSED_TILE_ID,
    ...payload,
});

export const setlastFocusDetails = payload => ({
    type: LayoutActions.SET_LAST_FOCUS_DETAILS,
    ...payload,
});
export const purgeLayoutExcluding = payload => ({
    type: LayoutActions.PURGE_LAYOUT_EXCLUDING,
    ...payload,
});

export const isfetchingLayout = payload => ({
    type: LayoutActions.IS_FETCHING_LAYOUT,
    ...payload,
});

export const setLayoutDimensionMap = payload => ({
    type: LayoutActions.SET_LAYOUT_DIMENSIONS_MAP,
    payload: { ...payload },
});

export const resetLayoutDimensionMap = payload => ({
    type: LayoutActions.RESET_LAYOUT_DIMENSIONS_MAP,
    payload: { ...payload },
});

export const searchLayoutUpdate = payload => ({
    type: LayoutActions.SEARCH_LAYOUT_UPDATE,
    ...payload,
});

export const searchLayoutReset = payload => ({
    type: LayoutActions.SEARCH_LAYOUT_RESET,
    ...payload,
});


export const layoutRailScrollTopUpdate = payload => ({
    type: LayoutActions.LAYOUT_RAIL_SCROLL_TOP_UPDATE,
    payload: { ...payload },
});

export const updateUserConfigPurgeLayout = () => ({
    type: LayoutActions.UPDATE_USER_CONFIG_PURGE_LAYOUT,
});

export const setRailScrollTopAddition = payload => ({
    type: LayoutActions.RAIL_SCROLL_TOP_ADDITION,
    payload: { ...payload },
});

export const refreshDimensionMap = payload => ({
    type: LayoutActions.REFRESH_DIMENSION_MAP,
    ...payload,
})